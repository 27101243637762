<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="1200px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :inline="true"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="产品名称" prop="productName">
          <el-input
            v-model="airForm.productName"
            placeholder="请输入产品名称"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="批号" prop="batchNumber">
          <el-input
            v-model="airForm.batchNumber"
            placeholder="请输入批号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="规格" prop="spec">
          <el-input
            v-model="airForm.spec"
            placeholder="请输入规格"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="结论" prop="conclusion">
          <el-radio-group
            v-model="airForm.conclusion"
            :disabled="handleType !== 'add' && handleType !== 'update'"
          >
            <el-radio label="符合规定"> 符合规定 </el-radio>
            <el-radio label="不符合规定"> 不符合规定 </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-col :span="24" v-if="handleType === 'update'">
          <el-form-item label="车间审核">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              v-if="!airForm.workshopAuditor"
              @click.stop="handleConform(airForm.id)"
            >
              审核
            </el-button>
            <p class="el-input__inner" style="margin:0;" v-else>
                <span>{{airForm.workshopAuditor}}</span>  <span>{{airForm.workshopReviewTime}}</span>
            </p>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="handleType === 'update' ">
          <el-form-item label="质量部审核">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              v-if="!airForm.qualityAuditor"
              @click.stop="handleConform2(airForm.id)"
            >
              审核
            </el-button>
            <p class="el-input__inner" style="margin:0;" v-else>
                <span>{{airForm.qualityAuditor}}</span>  <span>{{airForm.qualityReviewTime}}</span>
            </p>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
      <section v-if="handleType != 'add'">
        <el-button
          type="primary"
          @click="handleAdd2"
          v-if="handleType === 'update'"
        >
          新 增
        </el-button>
        <el-table :data="airForm.detailList" style="width: 100%">
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column
            prop="productionRecordContent"
            label="批生产记录内容"
            align="center"
          />
          <el-table-column label="审核结果" align="center">
            <el-table-column
              prop="meetClaim"
              label="批生产记录内容"
              align="center"
            />
            <el-table-column prop="meetClaim" label="结论" align="center" >
              <template slot-scope="scope">
                {{scope.row.meetClaim === '1'?'符合要求':'不符合要求'}}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-delete"
                size="small"
                @click.stop="handleDelete2(scope.$index, airForm.detailList)"
                v-if="handleType === 'update'"
              >
                删除
              </el-button>
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="handleUpdate2(scope.$index, scope.row)"
                v-if="handleType === 'update'"
              >
                修改
              </el-button>
              <el-button
                type="text"
                icon="el-icon-info"
                size="small"
                @click.stop="handleInfo2(scope.$index, scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div slot="footer">
          <el-button @click="airDialogVisible = false">关闭</el-button>
        </div> -->
      </section>
      <el-dialog
        width="60%"
        title="新增操作记录"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="150px"
        >
          <el-form-item label="批生产记录内容" prop="productionRecordContent">
            <el-input
              v-model="airDetailForm.productionRecordContent"
              placeholder="请输入批生产记录内容"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="结论" prop="meetClaim">
            <el-radio-group
              v-model="airDetailForm.meetClaim"
              :disabled="airDetailForm.inputType === 'detail'"
            >
              <el-radio label="1"> 符合要求 </el-radio>
              <el-radio label="2"> 不合格 </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="缺陷" prop="defect">
            <el-input
              v-model="airDetailForm.defect"
              placeholder="请输入缺陷"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible = false"> 取 消 </el-button>
          <el-button type="primary" @click="productFormSubmit2">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input
          v-model="searchForm.productName"
          placeholder="请输入产品名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="airList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNumber" label="批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="conclusion" label="结论" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="airList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getRecordList,
  deleteRecordList,
  qualityReviewRecordList,
  workshopReviewRecordList,
  addRecordList,
  addRecordInfoDetail,
  getRecordDetail,
  updateRecordDetail,
} from "@/api/produce/produce.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      airList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: "",
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        productionRecordContent: [
          {
            required: true,
            message: "批生产记录内容不能为空",
            trigger: ["blur", "change"],
          },
        ],
        meetClaim: [
          {
            required: true,
            message: "结论不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      productFormRules2: {
        productName: [
          {
            required: true,
            message: "产品名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        batchNumber: [
          {
            required: true,
            message: "批号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        spec: [
          {
            required: true,
            message: "规格不能为空",
            trigger: ["blur", "change"],
          },
        ],
        conclusion: [
          {
            required: true,
            message: "结论不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let airList = await getRecordList(params);
    this.airList = airList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addRecordList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            params.reviewDetailJson = JSON.stringify(params.detailList);
            delete params.detailList;
            delete params.workshopAuditor;
            delete params.workshopReviewTime;
            delete params.qualityAuditor;
            delete params.qualityReviewTime;
            delete params.status;
            delete params.workshopReviewResult;
            delete params.qualityReviewResult;
            await updateRecordDetail(params);
          }
          this.airList = await getRecordList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.reviewId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            console.log(this.airDetailForm, "this.airDetailForm");
            let data = await addRecordInfoDetail(this.airDetailForm);
            let list = this.airForm.detailList;
            list.push(data);
            this.airForm.detailList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.detailList;
            console.log(this.airDetailForm, "this.airDetailForm");
            let index = this.airDetailForm.index;
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            console.log(list, "list");
            console.log(this.airDetailForm, "this.airDetailForm");
            list.splice(index, 1, this.airDetailForm);
            this.airForm.detailList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增生产记录审核单";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleAdd2() {
      this.handleDetailType = "add";
      this.innerVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteRecordList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.airList = await getRecordList(this.searchForm);
      });
    },
    handleDelete2(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        console.log(row, "row");
        console.log(index, "index");
        row.splice(index, 1);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "生产记录审核单";
      this.handleType = "update";
      getRecordDetail(row.id).then((res) => {
        console.log(res);
        this.airForm = { ...res };
        console.log(this.airForm);
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "生产记录审核单";
      this.handleType = "detail";
      getRecordDetail(row.id).then((res) => {
        this.airForm = { ...res };
        console.log(this.airForm);
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getRecordList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.airList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getRecordList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getRecordList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    handleInfo2(index, row) {
      this.handleDetailType = "detail";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.inputType = "detail";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleUpdate2(index, row) {
      this.handleDetailType = "update";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.index = index;
      airDetailForm.inputType = "update";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleConform(id) {
      const h = this.$createElement;
      this.$msgbox({
        title: "车间审核",
        closeOnClickModal:false,
        message: h("p", { style: "text-align: center" }, [
          h("p", { style: "margin-bottom: 15px" }, "你确定进行车间审核？"),
          h(
            "el-button",
            {
              on: {
                click: () => this.handleWorkshopReview(id, 2),
              },
            },
            "不通过"
          ),
          h(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: () => this.handleWorkshopReview(id, 1),
              },
            },
            "通过"
          ),
        ]),
        showCancelButton: false,
        showConfirmButton: false,
      }).then((action) => {
        this.$message({
          type: "info",
          message: "action: " + action,
        });
      });
    },
    async handleWorkshopReview(id, workshopReviewResult) {
      console.log(id);
      console.log(workshopReviewResult);
        let params = {
          id: id,
          workshopReviewResult: workshopReviewResult,
        };
        await workshopReviewRecordList(params);
        getRecordDetail(id).then((res) => {
          this.airForm = { ...res };
            this.$msgbox.close()
        });
    },
    handleConform2(id) {
      const h = this.$createElement;
      this.$msgbox({
        title: "质量部审核",
        closeOnClickModal:false,
        message: h("p", { style: "text-align: center" }, [
          h("p", { style: "margin-bottom: 15px" }, "你确定进行质量部审核？"),
          h(
            "el-button",
            {
              on: {
                click: () => this.handleQualityReview(id, 2),
              },
            },
            "不通过"
          ),
          h(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: () => this.handleQualityReview(id, 1),
              },
            },
            "通过"
          ),
        ]),
        showCancelButton: false,
        showConfirmButton: false,
      }).then((action) => {
        this.$message({
          type: "info",
          message: "action: " + action,
        });
      });
    },
    async handleQualityReview(id, workshopReviewResult) {
      console.log(id);
      console.log(workshopReviewResult);
        let params = {
          id: id,
          workshopReviewResult: workshopReviewResult,
        };
        await qualityReviewRecordList(params);
        getRecordDetail(id).then((res) => {
          this.airForm = { ...res };
            this.$msgbox.close()
        });
    },
  },

  watch: {
    runningTime(val, oldVal) {
      this.airForm.startupTime = val ? val[0] : "";
      this.airForm.offTime = val ? val[1] : "";
    },
    airDialogVisible(val){
      if(!val){
        this.airForm = {}
        this.runningTime = []
      }
    },
    innerVisible(val){
      if(!val){
        this.airDetailForm = {}
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
